import { clsx } from '@digital-spiders/misc-utils';
import type { Testimonial } from '../../api/types';
import Image from '../ui/Image';
import * as styles from './TestimonialCard.module.scss';

export type TestimonialCardProps = Testimonial & {
  className?: string;
};

const TestimonialCard = ({
  image,
  quote,
  author,
  position,
  className,
}: TestimonialCardProps): React.ReactElement => {
  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.imageContainer}>
        <Image image={image} className={styles.image} width={100} height={100} />
      </div>
      <div className={styles.contentContainer}>
        <blockquote className={styles.testimonial}>"{quote}"</blockquote>
        <div className={styles.bottomInfoContainer}>
          <div className={styles.personInfoContainer}>
            <p className={styles.name}>{author}</p>
            <p className={styles.position}>{position}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
