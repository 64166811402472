import { clsx } from '@digital-spiders/misc-utils';
import { useWindowDimensions } from '@digital-spiders/react-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { BiSolidChevronLeft, BiSolidChevronRight } from 'react-icons/bi';
import type { Testimonial } from '../../api/types';
import TestimonialCard from './TestimonialCard';
import * as styles from './TestimonialCarousel.module.scss';

export interface CarouselProps {
  testimonials: Array<Testimonial>;
  className?: string;
}

const TestimonialCarousel = ({ testimonials, className }: CarouselProps): React.ReactElement => {
  const [currentPage, setCurrentPage] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0); // Track the tallest page's height
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsPerPage = 3;
  const totalPages = Math.ceil(testimonials.length / itemsPerPage);

  // Calculate the tallest page's height based on summed heights of all pages
  useEffect(() => {
    const calculateTallestPage = () => {
      const allHeights: number[] = [];
      const container = containerRef.current;

      if (!container) return;

      Array.from(container.children).forEach(child => {
        allHeights.push((child as HTMLElement).offsetHeight);
      });

      // Group heights into trios (pages) and calculate their total height
      const pageHeights: number[] = [];
      for (let i = 0; i < totalPages; i++) {
        const start = i * itemsPerPage;
        const end = start + itemsPerPage;
        const trioHeights = allHeights.slice(start, end);
        const totalHeight = trioHeights.reduce((sum, height) => sum + height, 0);

        // Add the gaps (var(--spacing-medium) between items * 2 gaps per page)
        pageHeights.push(totalHeight + 48);
      }

      setMaxHeight(Math.max(...pageHeights)); // Set the tallest page's total height
    };

    calculateTallestPage();
  }, [testimonials, totalPages]);

  const handleDotClick = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  const windowDimensions = useWindowDimensions();

  const isSmallLaptop = windowDimensions
    ? windowDimensions.width && windowDimensions.width >= 1200
    : false;

  return (
    <div className={`${styles.testimonialsContainer} ${className || ''}`}>
      <div
        ref={containerRef}
        className={styles.testimonials}
        style={{ minHeight: isSmallLaptop ? `${maxHeight}px` : undefined }} // Apply the tallest page's height
      >
        {testimonials.map((testimonial, index) => (
          <div
            className={clsx(
              styles.testimonialCard,
              testimonials.length >= 3 &&
                isCurrentPageItem(index) &&
                (index % itemsPerPage) % 2 === 0 &&
                styles.oddItem,
              testimonials.length >= 3 &&
                isCurrentPageItem(index) &&
                (index % itemsPerPage) % 2 === 1 &&
                styles.evenItem,
            )}
            key={index}
            style={{
              visibility: isCurrentPageItem(index) ? 'visible' : 'hidden',
              position: isCurrentPageItem(index) ? 'relative' : 'absolute',
              top: 0,
              left: 0,
            }}
          >
            <TestimonialCard {...testimonial} />
          </div>
        ))}
      </div>
      {testimonials.length > 3 && (
        <div className={styles.pagination}>
          <button
            className={clsx(styles.arrow, styles.leftArrow, currentPage === 0 && styles.disabled)}
            onClick={() => handleDotClick(currentPage - 1)}
            disabled={currentPage === 0}
          >
            <BiSolidChevronLeft />
            <div className={styles.visuallyHidden}>Previous page</div>
          </button>
          <div className={styles.dotsContainer}>
            {Array.from({ length: totalPages }).map((_, i) => (
              <button
                key={i}
                className={`${styles.dot} ${i === currentPage ? styles.activeDot : ''}`}
                onClick={() => handleDotClick(i)}
              >
                <div className={styles.visuallyHidden}>Page {i + 1}</div>
              </button>
            ))}
          </div>
          <button
            className={clsx(
              styles.arrow,
              styles.rightArrow,
              currentPage === totalPages - 1 && styles.disabled,
            )}
            onClick={() => handleDotClick(currentPage + 1)}
            disabled={currentPage === totalPages - 1}
          >
            <BiSolidChevronRight />
            <div className={styles.visuallyHidden}>Next page</div>
          </button>
        </div>
      )}
    </div>
  );

  function isCurrentPageItem(index: number) {
    return Math.floor(index / itemsPerPage) === currentPage;
  }
};

export default TestimonialCarousel;
